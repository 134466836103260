import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from 'pkg/util/classnames';
import { Fragment, ReactNode, useRef, useState } from 'react';

export interface Button {
	id: string;
	text: ReactNode;
	className?: string;
	cancel?: boolean;
	disabled?: boolean;
}

export interface Props<T extends {}> {
	title?: ReactNode;
	description?: ReactNode;
	icon?: ReactNode;
	iconColor?: string;
	form: (_: {
		data: T | null;
		onChange: (data: T | null) => void;
	}) => ReactNode;
	buttons?: Button[] | ((data: T | null) => Button[]);
	cancelButton?: boolean;
	sidecarLeft?: ReactNode;
	sidecarRight?: ReactNode;
}

export default function Prompt<T extends {}>(
	props: Props<T> & {
		open: boolean;
		initialData: T | null;
		onClose: (_: { button: Button | null; data: T | null }) => void;
	}
) {
	const cancelButtonRef = useRef(null);
	const [data, setData] = useState<T | null>(props.initialData);
	const [scrollTop, setScrollTop] = useState(0);

	const buttonsArray = !props.buttons
		? []
		: Array.isArray(props.buttons)
		? props.buttons
		: props.buttons(data);

	const buttons =
		buttonsArray.map((button, index) => (
			<button
				key={button.id}
				type="button"
				className={classNames(
					'ml-2 inline-flex justify-center rounded-md px-3.5 py-2 text-sm font-semibold shadow-sm mt-0 w-auto focus:outline-none focus-visible:outline focus-visible:ring-2 focus-visible:ring-white enabled:hover:bg-opacity-80 disabled:opacity-50',
					button.className ?? 'bg-gray-500 text-white'
				)}
				onClick={() => {
					props.onClose({ button, data });
				}}
				ref={button.cancel ? cancelButtonRef : undefined}
				disabled={button.disabled}
			>
				{button.text}
			</button>
		)) ?? [];

	return (
		<Transition.Root show={props.open} as={Fragment} appear>
			<Dialog
				as="div"
				className="relative z-50"
				initialFocus={cancelButtonRef}
				onClose={() => props.onClose({ button: null, data })}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
				</Transition.Child>

				<div
					className="fixed inset-0 z-10 overflow-y-auto"
					onScroll={(e) => setScrollTop(e.currentTarget.scrollTop)}
				>
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-300"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								className={classNames('my-8', {
									flex: !!(
										props.sidecarLeft || props.sidecarRight
									),
									'w-full max-w-lg': !(
										props.sidecarLeft || props.sidecarRight
									),
								})}
							>
								{props.sidecarLeft && (
									<div
										className="flex-shrink-0 flex-grow-0 pr-4 text-left transition-all duration-300"
										style={{
											paddingTop: scrollTop,
										}}
									>
										<div
											onClick={(e) => e.stopPropagation()}
										>
											{props.sidecarLeft}
										</div>
									</div>
								)}
								<Dialog.Panel className="relative bg-slate-700 transform rounded-lg text-left shadow-xl transition-all w-full max-w-lg">
									<div className="px-4 pb-4 pt-5 bg-black/10">
										{props.cancelButton !== false && (
											<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
												<button
													type="button"
													className="rounded-md bg-gray-900 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
													onClick={() =>
														props.onClose({
															button: null,
															data,
														})
													}
												>
													<span className="sr-only">
														Close
													</span>
													<XMarkIcon
														className="h-6 w-6"
														aria-hidden="true"
													/>
												</button>
											</div>
										)}
										<div className="sm:flex sm:items-start">
											{props.icon && (
												<div
													className={`flex flex-shrink-0 items-center justify-center rounded-full bg-${
														props.iconColor ??
														'transparent'
													} bg-opacity-50 mx-0 ${
														props.description
															? 'h-10'
															: 'h-6'
													} w-10`}
												>
													{props.icon}
												</div>
											)}
											<div
												className={classNames(
													'mt-0 text-left',
													{
														'ml-4': !!props.icon,
														'ml-2': !props.icon,
													}
												)}
											>
												<Dialog.Title
													as="h3"
													className="text-base font-semibold leading-6 text-white"
												>
													{props.title}
												</Dialog.Title>
												{props.description && (
													<div className="mt-2">
														<p className="text-sm text-gray-400">
															{props.description}
														</p>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="px-6 py-3">
										{props.form({
											data,
											onChange: setData,
										})}
									</div>
									<div className="py-3 px-6 text-right">
										{buttons}
									</div>
								</Dialog.Panel>
								{props.sidecarRight && (
									<div
										className="flex-shrink-0 flex-grow-0 pl-4 text-left transition-all duration-300"
										style={{
											paddingTop: scrollTop,
										}}
									>
										<div
											onClick={(e) => e.stopPropagation()}
										>
											{props.sidecarRight}
										</div>
									</div>
								)}
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
