import UserDashboardSidebar from '../components/user/dashboard-sidebar';
import Dashboard from './dashboard';

interface Props extends React.PropsWithChildren {
	toolbar?: any;
	disableScroll?: boolean;
	mobileMode?: boolean;
}

export default function UserDashboardLayout({
	children,
	toolbar,
	disableScroll,
	mobileMode,
}: Props) {
	return (
		<Dashboard
			toolbar={toolbar}
			sidebar={<UserDashboardSidebar />}
			disableScroll={disableScroll}
			mobileMode={mobileMode}
		>
			{children}
		</Dashboard>
	);
}
