import {
	RegistrationChallenge,
	RegistrationResponse,
} from '@spiretechnology/js-webauthn';
import { Credential } from '../proto/mfa';
import { Api } from '../services/api';

export async function listMfaCredentials(api: Api): Promise<Credential[]> {
	const { credentials } = await api.request<{ credentials: Credential[] }>(
		'/v1/mfa/list'
	);
	return credentials;
}

export async function deleteMfaCredential(
	api: Api,
	credential_id: number
): Promise<void> {
	await api.request('/v1/mfa/delete', { credential_id });
}

export function registerChallenge(api: Api): Promise<RegistrationChallenge> {
	return api.request<RegistrationChallenge>('/v1/mfa/register-challenge');
}

export async function registerVerify(
	api: Api,
	res: RegistrationResponse
): Promise<Credential> {
	const { credential } = await api.request<{ credential: Credential }>(
		'/v1/mfa/register-verify',
		res
	);
	return credential;
}
