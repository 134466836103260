import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Api } from '../services/api';
import { siteConfig } from '../services/site_config';

/**
 * The key for the token in local storage.
 */
const TOKEN_STORAGE_KEY = 'spire-auth-token';

interface State {
	api: Api;
}

function _getAuthToken(): string | null {
	const token =
		document.cookie
			.split('; ')
			.find((row) => row.startsWith(`${TOKEN_STORAGE_KEY}=`))
			?.split('=')[1] ?? null;
	if (!token || token === 'null') return null;
	return token;
}

function _setAuthToken(token: string | null): void {
	document.cookie = `${TOKEN_STORAGE_KEY}=${
		token ?? 'null'
	}; path=/; SameSite=Strict;`;
}

// The initial state to use for the context
const initialState: State = {
	api: new Api(siteConfig.api_baseurl, _getAuthToken()),
};

// Store the context and the setter function
const context = createContext<State>(initialState);
let setState: React.Dispatch<React.SetStateAction<State>> | undefined;

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	const [state, _setState] = useState(initialState);
	setState = _setState;
	return <context.Provider value={state}>{children}</context.Provider>;
}

// Hook to retrieve an API client
export function useApi() {
	// Create a hook for a local copy of the state
	const state = useContext<State>(context);

	// Function to set the base url
	const setBaseUrl = (baseUrl: string) => {
		setState?.({
			...state,
			api: new Api(baseUrl, state.api.authToken),
		});
	};

	// Function to set the auth token
	const setAuthToken = (authToken: string | null): Api => {
		_setAuthToken(authToken);
		const newApi = new Api(state.api.baseUrl, authToken);
		setState?.({
			...state,
			api: newApi,
		});
		return newApi;
	};

	return {
		...state,
		setBaseUrl,
		setAuthToken,
	};
}
