import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'pkg/util/classnames';
import { PASSWORD_CHECKS } from '../util/password-validate';

interface Props {
	password: string;
	className?: string;
}

export default function PasswordStrengthBox({ password, className }: Props) {
	return (
		<div className={className ?? 'text-xs mt-2 rounded-md bg-black/30 p-2'}>
			{PASSWORD_CHECKS.map((check, index) => {
				const ok = check.check(password);
				return (
					<div
						key={index}
						className={classNames({
							'text-gray-500': !ok,
							'text-green-500': ok,
						})}
					>
						<FontAwesomeIcon
							icon={ok ? faCheck : faTimes}
							fixedWidth
						/>
						<span className="ml-2">{check.name}</span>
					</div>
				);
			})}
		</div>
	);
}
