import { Api } from '../services/api';

export interface InitiateSignupRequest {
	name: string;
	email: string;
	password: string;
}

export interface InitiateSignupResponse {
	signup_intent: string;
}

export interface VerifySignupRequest {
	signup_request: InitiateSignupRequest;
	signup_intent: string;
	code: string;
}

export interface VerifySignupResponse {
	correct_code: boolean;
	token: string;
}

export function signup(
	api: Api,
	req: InitiateSignupRequest
): Promise<InitiateSignupResponse> {
	return api.request<InitiateSignupResponse>('/v1/signup/initiate', req);
}

export function verify(
	api: Api,
	req: VerifySignupRequest
): Promise<VerifySignupResponse> {
	return api.request<VerifySignupResponse>('/v1/signup/verify-email', req);
}
