import { PropsWithChildren, ReactNode } from 'react';

interface Props {
	title: ReactNode;
	description?: ReactNode;
}

export default function SettingsSection({
	title,
	description,
	children,
}: PropsWithChildren<Props>) {
	return (
		<div>
			<h2 className="text-xl font-semibold leading-7 text-white">
				{title}
			</h2>
			{description && (
				<p className="mt-1 text-sm leading-6 text-gray-400">
					{description}
				</p>
			)}
			<div>{children}</div>
		</div>
	);
}
