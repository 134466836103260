import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	AuthenticationResponse,
	WebAuthnClient,
} from '@spiretechnology/js-webauthn';
import { classNames } from 'pkg/util/classnames';
import { errorToString } from 'pkg/util/errors';
import { ucfirst } from 'pkg/util/uppercase';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MfaNeededResponse, basic_login, mfa_login } from '../api/login';
import { useApi } from '../hooks/api';
import { Api } from '../services/api';
import { KeyIcon } from '@heroicons/react/24/solid';

interface Props {
	onLogin: (api: Api) => void;
	loginHint?: string | null;
}

type FormStatus =
	| { type: 'loading' }
	| { type: 'form-basic'; submitting: boolean; error: string | null }
	| { type: 'form-mfa'; submitting: boolean; error: string | null }
	| { type: 'redirecting' };

interface FormState {
	basic: {
		email: string;
		password: string;
	};
	mfa: null | MfaNeededResponse;
}

export default function LoginForm({ onLogin, loginHint }: Props) {
	const webauthn = useMemo(() => new WebAuthnClient(), []);
	const { api, setAuthToken } = useApi();
	const passwordFieldRef = useRef<HTMLInputElement>(null);
	const [status, setStatus] = useState<FormStatus>({
		type: 'form-basic',
		submitting: false,
		error: null,
	});
	const [formState, setFormState] = useState<FormState>({
		basic: {
			email: loginHint ?? '',
			password: '',
		},
		mfa: null,
	});

	const successWithToken = (token: string) => {
		setStatus({ type: 'redirecting' });
		const newApi = setAuthToken(token);
		onLogin(newApi);
	};

	const clickedLogin = async () => {
		setStatus({
			type: 'form-basic',
			submitting: true,
			error: null,
		});
		try {
			const res = await basic_login(api, formState.basic);
			if (res.mfa_needed) {
				setFormState((state) => ({
					...state,
					mfa: res.mfa_needed,
				}));
				startMfaLogin(res.mfa_needed);
			} else if (res.success) {
				successWithToken(res.success.token);
			} else {
				setStatus({
					type: 'form-basic',
					submitting: false,
					error: 'Incorrect email or password',
				});
			}
		} catch (err) {
			setStatus({
				type: 'form-basic',
				submitting: false,
				error: ucfirst(errorToString(err)),
			});
			console.error(err);
		}
	};

	const startMfaLogin = async (mfa: MfaNeededResponse) => {
		// Complete the MFA device authentication
		let result: AuthenticationResponse | undefined;
		try {
			setStatus({
				type: 'form-mfa',
				submitting: false,
				error: null,
			});
			result = await webauthn.authenticate(mfa.challenge);
		} catch (err) {
			setStatus({
				type: 'form-basic',
				submitting: false,
				error: 'Failed to authenticate MFA device',
			});
			setFormState((state) => ({
				...state,
				basic: {
					...state.basic,
					password: '',
				},
				mfa: null,
			}));
			console.error(err);
			return;
		}

		// Send the MFA device authentication result to the server
		try {
			setStatus({
				type: 'form-mfa',
				submitting: true,
				error: null,
			});
			const res = await mfa_login(api, {
				login_intent: mfa.login_intent,
				response: result,
			});
			successWithToken(res.token);
		} catch (err) {
			setStatus({
				type: 'form-mfa',
				submitting: false,
				error: errorToString(err),
			});
			console.error(err);
			return;
		}
	};

	if (status.type === 'loading') {
		return <>Loading...</>;
	} else if (status.type === 'form-basic') {
		return (
			<div className="space-y-6">
				{status.error && (
					<div className="-mt-5 bg-red-500/30 p-4 text-sm font-semibold border border-red-500 rounded-md">
						{status.error}
					</div>
				)}
				<div>
					<label
						htmlFor="email"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Email address
					</label>
					<div className="mt-2">
						<input
							id="email"
							name="email"
							type="email"
							autoComplete="off"
							required
							value={formState.basic.email}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									basic: {
										...state.basic,
										email: value,
									},
									mfa: null,
								}));
							}}
							className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									passwordFieldRef.current?.select();
								}
							}}
						/>
					</div>
				</div>

				<div>
					<label
						htmlFor="password"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Password
					</label>
					<div className="mt-2">
						<input
							id="password"
							name="password"
							type="password"
							autoComplete="off"
							required
							ref={passwordFieldRef}
							value={formState.basic.password}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									basic: {
										...state.basic,
										password: value,
									},
									mfa: null,
								}));
							}}
							className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									clickedLogin();
								}
							}}
						/>
					</div>
				</div>

				<div>
					<button
						type="submit"
						className="w-full rounded-md disabled:bg-blue-700/50 disabled:italic bg-blue-600 px-3 h-10 leading-10 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
						disabled={status.submitting}
						onClick={clickedLogin}
					>
						<FontAwesomeIcon
							icon={faSpinnerThird}
							spin
							className={classNames('transition-all', {
								'opacity-0 w-0': !status.submitting,
								'opacity-100 w-4 mr-1': status.submitting,
							})}
						/>
						<span>
							{status.submitting ? 'Logging in...' : 'Log in'}
						</span>
					</button>
				</div>
			</div>
		);
	} else if (status.type === 'form-mfa') {
		return (
			<div className="space-y-6">
				{status.error && (
					<div className="-mt-5 bg-red-500/30 p-4 text-sm font-semibold border border-red-500 rounded-md">
						{status.error}
					</div>
				)}
				<div className="flex bg-blue-500/30 p-4 text-sm border border-white/50 rounded-md">
					<div className="flex-grow-0 flex flex-col flex-shrink-0 w-8">
						<div className="flex-grow flex-shrink"></div>
						<div className="h-6 flex-grow-0 flex-shrink-0">
							<KeyIcon className="w-6 h-6 text-white" />
						</div>
						<div className="flex-grow flex-shrink"></div>
					</div>
					<div className="flex-grow flex-shrink">
						Use your multi-factor authentication device to log into
						your Spire account.
					</div>
				</div>
			</div>
		);
	} else if (status.type === 'redirecting') {
		return <>Logging in...</>;
	} else {
		return <></>;
	}
}
