import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

interface Props {
	text?: ReactNode;
}

export default function LoadingSection({ text }: Props) {
	return (
		<div className="absolute inset-0 flex items-center justify-center">
			<div className="flex flex-col items-center">
				<div className="text-6xl">
					<FontAwesomeIcon icon={faSpinnerThird} spin />
				</div>
				<div className="mt-6 text-sm text-white opacity-50 font-semibold">
					{text ?? <>Loading...</>}
				</div>
			</div>
		</div>
	);
}
