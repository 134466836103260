import { Helmet } from 'react-helmet-async';
import MfaList from '../../components/user/mfa-list';
import UserDashboardLayout from '../../layouts/user-dashboard';

export default function MfaPage() {
	return (
		<UserDashboardLayout>
			<Helmet>
				<title>Security | Spire Technology</title>
			</Helmet>
			<div className="w-full max-w-3xl mx-auto py-10">
				<div>
					<h1 className="text-3xl font-semibold">Security</h1>
					<p className="mt-2 text-sm leading-6 text-gray-400">
						Secure your Spire account with multi-factor
						authentication.
					</p>
				</div>
				<div className="mt-6">
					<MfaList />
				</div>
			</div>
		</UserDashboardLayout>
	);
}
