import { useApps } from '../../hooks/apps';

export default function AppsGrid() {
	const { apps } = useApps();
	return (
		<div className="grid grid-cols-4 gap-4">
			{apps.map((app, index) => (
				<div
					key={index}
					className="rounded-lg shadow-lg hover:shadow-black/50 bg-white/20 flex flex-col cursor-pointer hover:scale-105 transition-all"
					onClick={() => (location.href = app.link)}
				>
					<div
						className="flex-grow flex-shrink h-28 bg-black/10 bg-cover bg-center rounded-t-lg"
						style={{ backgroundImage: `url(${app.image})` }}
					></div>
					<div className="flex-grow-0 flex-shrink-0 h-10 flex items-center justify-center">
						<div className="uppercase font-semibold text-sm">
							{app.name}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
