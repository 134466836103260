import streamsaver from 'streamsaver';
import { siteConfig } from './site_config';

type RawResponse<T> = T | { error: string };

export class Api {
	public constructor(
		public baseUrl: string,
		public authToken: string | null
	) {}

	private async fetch(url: string, data?: any): Promise<Response> {
		// Get the base URL for the api
		url = `${this.baseUrl}${url}`;

		// Create the headers
		const headers: { [key: string]: string } = {};

		// Add the auth token
		if (this.authToken)
			headers['Authorization'] = `Bearer ${this.authToken}`;

		// Return the fetch response
		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(data ?? {}),
		});
	}

	/**
	 * Makes an API request and awaits a response
	 * @param url the url for the request
	 * @param data the data for the request
	 */
	public async request<TResponse>(
		url: string,
		data?: any
	): Promise<TResponse> {
		// Fetch the url
		const r = await this.fetch(url, data);

		// Parse the response from JSON
		let res: RawResponse<TResponse>;
		try {
			res = (await r.json()) as RawResponse<TResponse>;
		} catch (err) {
			throw new Error(`API returned status code ${r.status}`);
		}

		// If there is no response
		if (!res || typeof res !== 'object')
			throw new Error('invalid or empty API response');

		// If the response has an error value
		if ('error' in res) throw new Error(res.error);

		// Return the response body
		return res;
	}

	public async download(
		url: string,
		filename: string,
		data?: any
	): Promise<void> {
		// Send the request
		const res = await this.fetch(url, data);

		// Update the mitm path on stream saver
		streamsaver.mitm =
			'https://spiretechnology.github.io/streamsaver-mitm/mitm.html';

		// Create the file download stream
		const fileStream = streamsaver.createWriteStream(filename, {
			size: this.estimate_response_length(res),
		});

		// Pipe the body to the stream
		await res.body?.pipeTo(fileStream);
	}

	/**
	 * Estimates the content length for a response
	 */
	private estimate_response_length(res: Response): number | undefined {
		if (!res.headers.has('Content-Length')) return undefined;
		const contentLength = res.headers.get('Content-Length');
		if (!contentLength) return undefined;
		const val = parseInt(contentLength, 10);
		if (isNaN(val)) return undefined;
		return val;
	}
}
