import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useContext,
	useState,
} from 'react';
import Dialog, { Button, Props as DialogProps } from '../components/dialog';

interface DialogWrapperProps<T extends {}> {
	id: number;
	props: DialogProps<T>;
	initialData: T | null;
	resolve: (_: { button: Button | null; data: T | null }) => void;
}

interface State {
	queue: DialogWrapperProps<any>[];
}

// The initial state to use for the context
const initialState: State = {
	queue: [],
};

let nextDialogID = 0;

// Store the context and the setter function
const context = createContext<State>(initialState);

let setState: Dispatch<SetStateAction<State>> | undefined;

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	const [state, _setState] = useState(initialState);
	setState = _setState;
	return (
		<context.Provider value={state}>
			{state.queue.map((prompt, index) => (
				<DialogWrapper key={prompt.id} {...prompt} />
			))}
			{children}
		</context.Provider>
	);
}

function DialogWrapper<T extends {}>({
	id,
	props,
	initialData,
	resolve,
}: DialogWrapperProps<T>) {
	const [open, setOpen] = useState(true);
	return (
		<Dialog
			{...props}
			open={open}
			initialData={initialData}
			onClose={({ button, data }) => {
				resolve({ button, data });
				// Hide the prompt (begin the animation)
				setOpen(false);
				setTimeout(() => {
					// Update the queue by removing the leftmost prompt
					setState?.((state) => {
						return {
							...state,
							queue: state.queue.filter((d) => d.id !== id),
						};
					});
				}, 300);
			}}
		/>
	);
}

export function useDialogs() {
	const state = useContext<State>(context);

	// Get the state from the context
	const dialog = <T extends {}>(
		props: DialogProps<T>,
		initialData?: T
	): Promise<{ button: Button | null; data: T | null }> => {
		return new Promise((resolve) => {
			// Add the prompt to the queue
			setState?.((state) => {
				const id = nextDialogID;
				nextDialogID++;
				return {
					...state,
					queue: [
						...state.queue,
						{
							id,
							props,
							resolve,
							initialData: initialData ?? null,
						},
					],
				};
			});
		});
	};

	return {
		...state,
		dialog,
	};
}
