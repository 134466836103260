import { Dialog, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from 'pkg/util/classnames';
import { Fragment, useState } from 'react';
import { useAccount } from '../hooks/account';
import { siteBanner } from '../services/site_banner';
import { siteConfig } from '../services/site_config';

interface Props extends React.PropsWithChildren {
	sidebar?: any;
	toolbar?: any;
	disableScroll?: boolean;
	mobileMode?: boolean;
}

export default function DashboardLayout({
	children,
	toolbar,
	sidebar,
	disableScroll,
	mobileMode,
}: Props) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { account } = useAccount();
	sidebar = (
		<>
			<div className="flex flex-1 flex-col overflow-y-auto pt-2 pb-4">
				{sidebar}
				<div className="text-center pt text-xs text-gray-500 font-semibold">
					{siteConfig.version_tag}
				</div>
			</div>
		</>
	);
	return (
		<>
			<div className="flex h-[100dvh] w-screen flex-col">
				{siteBanner && (
					<div
						className="flex-0 h-5 leading-5 text-[11px] font-semibold text-center"
						style={{
							backgroundColor: siteBanner.background_color,
							color: siteBanner.text_color,
						}}
					>
						{siteBanner.text}
					</div>
				)}
				<div className="flex flex-grow flex-shrink overflow-hidden">
					<Transition.Root show={sidebarOpen} as={Fragment}>
						<Dialog
							as="div"
							className={classNames('relative z-40', {
								'lg:hidden': !mobileMode,
							})}
							onClose={setSidebarOpen}
						>
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
							</Transition.Child>

							<div className="fixed inset-0 z-40 flex">
								<Transition.Child
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transition ease-in-out duration-300 transform"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
										<Transition.Child
											as={Fragment}
											enter="ease-in-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in-out duration-300"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="absolute top-0 right-0 -mr-12 pt-2">
												<button
													type="button"
													className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
													onClick={() =>
														setSidebarOpen(false)
													}
												>
													<span className="sr-only">
														Close sidebar
													</span>
													<XMarkIcon
														className="h-6 w-6 text-white"
														aria-hidden="true"
													/>
												</button>
											</div>
										</Transition.Child>
										{sidebar}
									</Dialog.Panel>
								</Transition.Child>
								<div className="w-14 flex-shrink-0">
									{/* Force sidebar to shrink to fit close icon */}
								</div>
							</div>
						</Dialog>
					</Transition.Root>

					{/* Static sidebar for desktop */}
					<div
						className={classNames('relative hidden', {
							'hidden relative w-64 lg:flex lg:flex-col':
								!mobileMode,
						})}
					>
						<div className="flex min-h-0 flex-1 flex-col bg-gray-800">
							{sidebar}
						</div>
					</div>
					<div className="flex flex-1 flex-col bg-gray-900 h-full overflow-x-auto">
						<div
							className={classNames(
								'sticky top-0 z-20 bg-gray-800 h-14',
								{
									'lg:hidden': !toolbar,
								}
							)}
						>
							<div className="flex w-full h-full">
								<button
									type="button"
									className={classNames(
										'inline-flex h-full w-12 items-center justify-center rounded-md text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
										{
											'lg:hidden': !mobileMode,
										}
									)}
									onClick={() => setSidebarOpen(true)}
								>
									<span className="sr-only">
										Open sidebar
									</span>
									<Bars3Icon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</button>
								{toolbar && (
									<div className="flex-1 px-3">{toolbar}</div>
								)}
							</div>
						</div>
						<main
							className={classNames('flex-1 relative', {
								'overflow-auto': !disableScroll,
								'overflow-hidden': !!disableScroll,
							})}
						>
							{children}
						</main>
					</div>
				</div>
			</div>
		</>
	);
}
