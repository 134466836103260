import { KeyIcon } from '@heroicons/react/24/outline';
import { Credential } from '@internal/auth-web/proto/mfa';
import { usePrompts } from 'pkg/hooks/prompts';
import { ReactNode } from 'react';
import LoadingSection from '../../components/loading-section';
import { useMfaCredentials } from '../../hooks/mfa';
import SettingsSection from './settings-section';

export default function MfaList() {
	const { credentials, loading, register, deleteCredential } =
		useMfaCredentials();
	const { prompt } = usePrompts();

	const clickedDeleteCredential = async (credential: Credential) => {
		const button = await prompt({
			title: (
				<>
					Delete{' '}
					<span className="font-semibold">
						{credentialName(credential)}
					</span>
				</>
			),
			icon: <KeyIcon className="h-6 w-6" aria-hidden="true" />,
			iconColor: 'red-500',
			description: 'Are you sure you want to delete this credential?',
			buttons: [
				{ id: 'cancel', text: 'Cancel', cancel: true },
				{
					id: 'delete',
					text: 'Remove',
					className: 'bg-red-500',
				},
			],
		});
		if (!button || button.id === 'cancel') return;
		deleteCredential(credential);
	};

	if (loading) return <LoadingSection />;
	return (
		<SettingsSection title="Multi-Factor Authentication">
			<div className="mt-6">
				{credentials.length > 0 ? (
					<ul
						role="list"
						className="divide-y divide-gray-500 border-t border-gray-500 text-sm leading-6"
					>
						{credentials.map((credential) => (
							<li
								key={credential.id}
								className="flex gap-x-6 py-6"
							>
								<div className="flex-grow-0 flex-shrink-0 w-8 pl-2">
									<KeyIcon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</div>
								<div className="flex-grow flex-shrink font-medium text-white">
									{credentialName(credential)}
								</div>
								<div className="flex-grow-0 flex-shrink-0 text-right">
									<button
										type="button"
										className="font-semibold text-red-600 hover:text-red-500"
										onClick={() =>
											clickedDeleteCredential(credential)
										}
									>
										Delete
									</button>
								</div>
							</li>
						))}
					</ul>
				) : (
					<div className="mb-6 p-6 rounded-md bg-gray-800 text-sm">
						You haven't registered any multi-factor authentication
						credentials yet.
					</div>
				)}
			</div>

			<div className="flex border-t border-gray-500 pt-6">
				<button
					type="button"
					className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500"
					onClick={register}
				>
					<span aria-hidden="true">+</span> Register new credential
				</button>
			</div>
		</SettingsSection>
	);
}

function credentialName(credential: Credential): ReactNode {
	if (credential.name) return credential.name;
	if (!credential.authenticator) return 'Unnamed Device';
	return (
		credential.authenticator.name ??
		credential.authenticator.model ??
		credential.authenticator.manufacturer ??
		'Unnamed Device'
	);
}
