import {
	ArrowLeftOnRectangleIcon,
	KeyIcon,
	Squares2X2Icon,
	UserIcon,
} from '@heroicons/react/24/outline';
import { useAccount } from '@internal/auth-web/hooks/account';
import { classNames } from 'pkg/util/classnames';
import { Fragment, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type Nav = {
	name: string;
	icon: any;
	enabled?: boolean;
	widget?: ReactNode;
} & ({ href: string } | { onClick: () => void });

function Badge({ children }: { children: ReactNode }) {
	return (
		<span
			className={classNames(
				'ml-auto inline-block rounded-full py-0.5 px-3 text-xs bg-gray-500 text-white'
			)}
		>
			{children}
		</span>
	);
}

function filterNavGroup(group: ReactNode[]): ReactNode[] {
	return group.filter((link) => !!link);
}

function filterNav(navigation: ReactNode[][]): ReactNode[][] {
	return navigation.map(filterNavGroup).filter((group) => group.length > 0);
}

export default function UserDashboardSidebar() {
	const link = (item: Nav) => <NavLinkComponent item={item} />;
	const { logout } = useAccount();

	const navigation = filterNav([
		[
			link({
				name: 'Apps',
				href: '/dashboard/home',
				icon: Squares2X2Icon,
			}),
			link({
				name: 'Account',
				href: '/dashboard/account',
				icon: UserIcon,
			}),
			link({
				name: 'Security',
				href: '/dashboard/mfa',
				icon: KeyIcon,
			}),
		],
		[
			link({
				name: 'Log out',
				icon: ArrowLeftOnRectangleIcon,
				onClick: logout,
			}),
		],
	]);

	return (
		<>
			<div className="pl-4 pt-2">
				<img
					src="/logos/spire-technology-white-256.png"
					alt="Spire Technology"
					className="h-10 w-auto"
				/>
			</div>
			<nav className="mt-2 flex-1 px-2 divide-y divide-slate-900">
				{navigation.map((items, index) => (
					<div key={index} className="py-3 space-y-1">
						{items.map((item, itemIndex) => (
							<Fragment key={itemIndex}>{item}</Fragment>
						))}
					</div>
				))}
			</nav>
		</>
	);
}

function NavLinkComponent({ item }: { item: Nav }) {
	const buttonClassNames = (isActive: boolean) =>
		classNames(
			'group flex items-center rounded-md px-2 py-2 text-sm font-medium transition-transform w-full',
			{
				'bg-gray-900 text-white': isActive,
				'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive,
			}
		);
	const inner = (isActive: boolean) => (
		<>
			<item.icon
				className={classNames('mr-3 h-6 w-6 flex-shrink-0', {
					'text-gray-300': isActive,
					'text-gray-400 group-hover:text-gray-300': !isActive,
				})}
				aria-hidden="true"
			/>
			<span className="truncate">{item.name}</span>
			{item.widget}
		</>
	);
	if ('onClick' in item) {
		return (
			<button onClick={item.onClick} className={buttonClassNames(false)}>
				{inner(false)}
			</button>
		);
	}
	return (
		<NavLink
			to={item.href}
			className={({ isActive }) => buttonClassNames(isActive)}
		>
			{({ isActive }) => inner(isActive)}
		</NavLink>
	);
}
