export interface SiteConfig {
	api_baseurl: string;
	git_shasum?: string;
	ufe_shasum?: string;
	version_tag?: string;
	banner_text?: string;
	banner_color?: string;
	banner_text_color?: string;
}

export const siteConfig: SiteConfig = {
	api_baseurl: process.env.REACT_APP_API_BASEURL ?? 'http://localhost:8080',
	git_shasum: process.env.REACT_APP_GIT_SHASUM,
	ufe_shasum: process.env.REACT_APP_UFE_SHASUM,
	version_tag: process.env.REACT_APP_VERSION_TAG,
	banner_text: process.env.REACT_APP_BANNER_TEXT,
	banner_color: process.env.REACT_APP_BANNER_COLOR,
	banner_text_color: process.env.REACT_APP_BANNER_TEXT_COLOR,
};
