import { useEffect, useMemo, useState } from 'react';
import { Account } from '../../proto/account';

interface FormState {
	name: string | null;
	email: string;
}

interface Props {
	account: Account;
	editing: boolean;
	onCancel: () => void;
	onSave: () => void;
}

export default function AccountForm({
	account,
	editing,
	onCancel,
	onSave,
}: Props) {
	const [form, setForm] = useState<FormState>({
		name: account.name,
		email: account.email,
	});

	useEffect(() => {
		if (!editing) {
			setForm({
				name: account.name,
				email: account.email,
			});
		}
	}, [editing]);

	const isDirty = useMemo(() => {
		return (
			form.name?.trim() !== account.name?.trim() ||
			form.email.trim() !== account.email.trim()
		);
	}, [form, account]);

	return (
		<form>
			<div className="space-y-12">
				<div className="border-b border-white/10 pb-12">
					<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div className="sm:col-span-4">
							<label
								htmlFor="first-name"
								className="block text-sm font-medium leading-6 text-white"
							>
								Name
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="first-name"
									id="first-name"
									readOnly={!editing}
									value={form.name ?? ''}
									onInput={(e) => {
										const value = e.currentTarget.value;
										setForm((form) => ({
											...form,
											name: value,
										}));
									}}
									autoComplete="given-name"
									className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-4">
							<label
								htmlFor="email"
								className="block text-sm font-medium leading-6 text-white"
							>
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									readOnly={!editing || true}
									value={form.email ?? ''}
									onInput={(e) => {
										const value = e.currentTarget.value;
										setForm((form) => ({
											...form,
											email: value,
										}));
									}}
									autoComplete="email"
									className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="border-b border-white/10 pb-12">
					<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div className="col-span-full">
							<label
								htmlFor="photo"
								className="block text-sm font-medium leading-6 text-white"
							>
								Photo
							</label>
							<div className="mt-2 flex items-center gap-x-3">
								<UserCircleIcon
									className="h-12 w-12 text-gray-500"
									aria-hidden="true"
								/>
								<button
									type="button"
									className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
								>
									Change
								</button>
							</div>
						</div>
					</div>
				</div> */}
			</div>

			{editing && (
				<div className="mt-6 flex items-center justify-end gap-x-6">
					<button
						type="button"
						className="text-sm font-semibold leading-6 text-white"
						onClick={onCancel}
					>
						Cancel
					</button>
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
						onClick={onSave}
						disabled={!isDirty}
					>
						Save
					</button>
				</div>
			)}
		</form>
	);
}
