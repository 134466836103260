import { PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingFullscreen from '../components/loading-fullscreen';
import { useAccount } from '../hooks/account';

interface Props extends PropsWithChildren {
	fallback?: ReactNode;
}

export function PrivateGuard({ children, fallback }: Props) {
	const { account, isLoading } = useAccount();
	if (isLoading) {
		return <LoadingFullscreen />;
	}
	if (!account) {
		const goto = document.location.pathname;
		return fallback ? (
			<>{fallback}</>
		) : (
			<Navigate to={`/login?goto=${encodeURIComponent(goto)}`} />
		);
	}
	return <>{children}</>;
}
