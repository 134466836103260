import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_oidc_code } from '../api/login';
import { getClientByID } from '../api/oidc';
import { OIDCClient } from '../proto/oidc_client';
import { parseOidcRequest } from '../services/oidc';
import { useApi } from './api';
import { Api } from '../services/api';

export function useAuthFlow() {
	const { api } = useApi();
	const navigate = useNavigate();
	const oidcRequest = useMemo(() => parseOidcRequest(), []);
	const goTo = useMemo(
		() => new URLSearchParams(window.location.search).get('goto'),
		[]
	);
	const [client, setClient] = useState<OIDCClient | null>(null);
	const [loadingClient, setLoadingClient] = useState(oidcRequest !== null);

	useEffect(() => {
		if (!oidcRequest) {
			setLoadingClient(false);
			setClient(null);
			return;
		}
		(async () => {
			try {
				setLoadingClient(true);
				const client = await getClientByID(api, oidcRequest.client_id);
				setClient(client);
			} catch (err) {
				console.error(err);
				setClient(null);
			} finally {
				setLoadingClient(false);
			}
		})();
	}, []);

	const handleSuccess = async (newApi?: Api) => {
		// If there is no client, enter the dashboard
		if (!oidcRequest) {
			navigate(goTo || '/dashboard');
			return;
		}

		try {
			// Fetch an OIDC code from the backend
			const code = await get_oidc_code(newApi ?? api, {
				code_challenge: oidcRequest.code_challenge,
				code_challenge_method: oidcRequest.code_challenge_method,
				client_id: oidcRequest.client_id,
				redirect_uri: oidcRequest.redirect_uri,
				scope: oidcRequest.scope,
			});

			// Create the redirect URL
			const url = new URL(oidcRequest.redirect_uri);
			url.searchParams.set('code', code);
			url.searchParams.set('state', oidcRequest.state);

			// Redirect to the client redirect_uri
			location.href = url.toString();
		} catch (err) {
			console.error(err);
		}
	};

	return {
		isSSO: oidcRequest !== null,
		client,
		loadingClient,
		handleSuccess,
	};
}
