import { Account } from '../proto/account';
import { Api } from '../services/api';

export async function whoami(api: Api): Promise<Account | null> {
	try {
		return await api.request<Account>('/v1/whoami');
	} catch (err) {
		console.error('whoami error: ', err);
		return null;
	}
}
