import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from 'pkg/util/classnames';
import { Fragment, ReactNode, useRef } from 'react';

export interface Button {
	id: string;
	text: ReactNode;
	className?: string;
	cancel?: boolean;
}

export interface Props {
	title?: ReactNode;
	description?: ReactNode;
	icon?: ReactNode;
	iconColor?: string;
	buttons?: Button[];
	cancelButton?: boolean;
}

export default function Prompt(
	props: Props & { open: boolean; onClose: (button: Button | null) => void }
) {
	const cancelButtonRef = useRef(null);

	const buttons =
		props.buttons?.map((button, index) => (
			<button
				key={button.id}
				type="button"
				className={classNames(
					'ml-2 inline-flex justify-center rounded-md px-3.5 py-2 text-sm font-semibold shadow-sm mt-0 w-auto focus:outline-none focus-visible:outline focus-visible:ring-2 focus-visible:ring-white hover:bg-opacity-80',
					button.className ?? 'bg-gray-500 text-white'
				)}
				onClick={() => {
					props.onClose(button);
				}}
				ref={button.cancel ? cancelButtonRef : undefined}
			>
				{button.text}
			</button>
		)) ?? [];

	return (
		<Transition.Root show={props.open} as={Fragment} appear>
			<Dialog
				as="div"
				className="relative z-50"
				initialFocus={cancelButtonRef}
				onClose={() => props.onClose(null)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-300"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-slate-700 transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									{props.cancelButton !== false && (
										<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
											<button
												type="button"
												className="rounded-md bg-gray-900 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
												onClick={() =>
													props.onClose(null)
												}
											>
												<span className="sr-only">
													Close
												</span>
												<XMarkIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</button>
										</div>
									)}
									<div className="sm:flex sm:items-start">
										{props.icon && (
											<div
												className={`flex flex-shrink-0 items-center justify-center rounded-full bg-${
													props.iconColor ??
													'transparent'
												} bg-opacity-50 mx-0 h-10 w-10`}
											>
												{props.icon}
											</div>
										)}
										<div
											className={classNames(
												'mt-0 text-left',
												{
													'ml-4': !!props.icon,
													'ml-2': !props.icon,
												}
											)}
										>
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-white"
											>
												{props.title}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-400">
													{props.description}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="py-3 px-6 text-right">
									{buttons}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
