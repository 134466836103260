import {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from 'react';
import { whoami } from '../api/auth';
import { Account } from '../proto/account';
import { useApi } from './api';

interface State {
	account: Account | null;
	isLoading: boolean;
}

// The initial state to use for the context
const initialState: State = {
	account: null,
	isLoading: true,
};

// Store the context and the setter function
const context = createContext<State>(initialState);
let setState: React.Dispatch<React.SetStateAction<State>> | undefined;

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	const { api } = useApi();

	const [state, _setState] = useState(initialState);
	setState = _setState;

	useEffect(() => {
		(async () => {
			// Start loading
			setState?.({
				...state,
				isLoading: true,
			});

			// Check the authentication (hard-coded for now)
			const account = api.authToken ? await whoami(api) : null;

			// Done loading
			setState?.({
				...state,
				account,
				isLoading: false,
			});
		})();
	}, [api.authToken]);

	return <context.Provider value={state}>{children}</context.Provider>;
}

// Hook to retrieve account data
export function useAccount() {
	// Get the api client
	const { setAuthToken } = useApi();

	// Create a hook for a local copy of the state
	const state = useContext<State>(context);

	return {
		...state,
		setAuthToken,
		logout: () => setAuthToken(null),
	};
}
