import { siteConfig } from './site_config';

export interface Banner {
	text: string;
	background_color: string;
	text_color: string;
}

const { banner_text, banner_color, banner_text_color } = siteConfig;

export const siteBanner: Banner | null = banner_text
	? {
			text: banner_text,
			background_color: banner_color ?? '#08F',
			text_color: banner_text_color ?? '#FFF',
	  }
	: null;
