import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import LoginForm from '../components/login-form';
import { useAccount } from '../hooks/account';
import { useAuthFlow } from '../hooks/auth-flow';

export default function LoginPage() {
	const { isSSO, client, loadingClient, handleSuccess } = useAuthFlow();
	const autoLogin = useMemo(
		() =>
			new URLSearchParams(window.location.search).get('auto') === 'true',
		[]
	);
	const loginHint = useMemo(
		() => new URLSearchParams(window.location.search).get('login_hint'),
		[]
	);
	const { account, isLoading } = useAccount();

	const clickedSignup = () => {
		if (isSSO) {
			window.location.href = `/sso/signup${window.location.search}`;
		} else {
			window.location.href = `/signup${window.location.search}`;
		}
	};

	// Auto login if there is an existing session (and if the 'auto' query param is set)
	useEffect(() => {
		if (account && !isLoading && isSSO && autoLogin) {
			handleSuccess();
		}
	}, [account, isLoading, isSSO, autoLogin]);

	return (
		<>
			<Helmet>
				<title>Login | Spire Technology</title>
			</Helmet>
			<div
				className="flex h-[100dvh] bg-[#141E3C]"
				style={{ backgroundImage: 'url(/background/bg-gradient.jpg)' }}
			>
				<div className="flex-1 flex flex-col px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 overflow-auto bg-black/20">
					<div className="flex-grow flex-shrink"></div>
					<div className="mx-auto w-full max-w-sm lg:w-96 flex-grow-0 flex-shrink-0">
						<div>
							<img
								className="h-14 w-auto"
								src="/logos/spire-technology-white-256.png"
								alt="Spire Technology"
							/>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
								Sign in {isSSO ? 'with' : 'to'} your account
							</h2>
							{client && (
								<p className="mt-2 text-sm leading-6 text-gray-500">
									Use your Spire account to access{' '}
									<span className="text-white/50">
										{client.name}
									</span>
								</p>
							)}
							<p className="mt-2 text-sm leading-6 text-gray-500">
								Don't have an account yet?{' '}
								<span
									className="text-blue-500 font-semibold cursor-pointer"
									onClick={clickedSignup}
								>
									Sign up{' '}
									<FontAwesomeIcon
										icon={faChevronRight}
										className="text-xs"
									/>
								</span>
							</p>
						</div>

						<div className="mt-10">
							<LoginForm
								onLogin={handleSuccess}
								loginHint={loginHint}
							/>
							<div className="pt-20">
								<span className="text-xs text-gray-400">
									Copyright &copy; {new Date().getFullYear()}{' '}
									Spire Technology, Inc.
									<br />
									Learn more at{' '}
									<a
										href="https://spiretechnology.com"
										target="_blank"
										className="text-blue-500"
									>
										spiretechnology.com
									</a>
								</span>
							</div>
						</div>
					</div>
					<div className="flex-grow flex-shrink"></div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block"></div>
			</div>
		</>
	);
}
