/**
 * Converts an error to a string, usually from a try/catch.
 * @param err the error value (string, object, etc.)
 * @param defaultValue the default error value to return
 * @returns the string value of the error
 */
export function errorToString(
	err: unknown,
	defaultValue: string = 'An unknown error occurred.'
): string {
	if (!err) return defaultValue;
	if (typeof err === 'string') return err;
	if (
		typeof err === 'object' &&
		'message' in err &&
		typeof err.message === 'string'
	)
		return err.message;
	return defaultValue;
}
