export interface OIDCRequest {
	response_type: string;
	client_id: string;
	redirect_uri: string;
	scope: string[];
	state: string;
	code_challenge: string;
	code_challenge_method: string;
	login_hint?: string;
}

/**
 * Parses the data in the OIDC request query parameters.
 */
export function parseOidcRequest(): OIDCRequest | null {
	// Parse the URL to get the search params
	const url = new URL(location.href);

	// Get all the values from the query string
	const response_type = url.searchParams.get('response_type');
	const client_id = url.searchParams.get('client_id');
	const redirect_uri = url.searchParams.get('redirect_uri');
	const scope = url.searchParams.get('scope')?.split(',') ?? ['openid'];
	const state = url.searchParams.get('state') ?? '';
	const code_challenge = url.searchParams.get('code_challenge');
	const code_challenge_method = url.searchParams.get('code_challenge_method');
	const login_hint = url.searchParams.get('login_hint') ?? undefined;

	// If any of the required values are missing
	if (
		response_type !== 'code' ||
		!client_id ||
		!redirect_uri ||
		!code_challenge ||
		!code_challenge_method
	)
		return null;

	// Return the parsed values
	return {
		response_type,
		client_id,
		redirect_uri,
		scope,
		state,
		code_challenge,
		code_challenge_method,
		login_hint,
	};
}
