import {
	createContext,
	Dispatch,
	PropsWithChildren,
	ReactNode,
	SetStateAction,
	useContext,
	useState,
} from 'react';

export type Notification =
	| { element: JSX.Element }
	| {
			icon?: ReactNode;
			title: ReactNode;
			description?: ReactNode;
			color?: string;
			accent?: string;
			buttons?: {
				text: ReactNode;
				onClick: (() => void) | 'dismiss';
			}[];
	  };

export type NotificationWithIndex = Notification & {
	id: number;
	shownAt: number;
	dismissAt?: number;
	showProgressBar?: boolean;
};

interface State {
	notifications: NotificationWithIndex[];
	next_index: number;
}

// The initial state to use for the context
const initialState: State = {
	notifications: [],
	next_index: 0,
};

// Store the context and the setter function
const context = createContext<State>(initialState);

let setState: Dispatch<SetStateAction<State>> | undefined;

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	const [state, _setState] = useState(initialState);
	setState = _setState;
	return <context.Provider value={state}>{children}</context.Provider>;
}

export function useNotifications() {
	const state = useContext<State>(context);

	// Get the state from the context
	const show = (
		notification: Notification,
		forTime?: number,
		showProgressBar?: boolean
	) => {
		return showAll([notification], forTime, showProgressBar);
	};
	const showAll = (
		notifications: Notification[],
		forTime?: number,
		showProgressBar?: boolean
	) => {
		const withIndices = notifications.map((n, index) => ({
			...n,
			id: state.next_index + index,
			shownAt: Date.now(),
			dismissAt: forTime ? Date.now() + forTime : undefined,
			showProgressBar,
		}));
		setState?.((prev) => {
			return {
				...prev,
				notifications: [...prev.notifications, ...withIndices],
				next_index: prev.next_index + notifications.length,
			};
		});
		return {
			dismiss: () => dismissAll(withIndices),
		};
	};
	const dismiss = (notification: NotificationWithIndex) => {
		dismissAll([notification]);
	};
	const dismissAll = (notifications: NotificationWithIndex[]) => {
		const ids = new Set<number>();
		notifications.forEach((n) => ids.add(n.id));
		setState?.((prev) => ({
			...prev,
			notifications: prev.notifications.filter((n) => !ids.has(n.id)),
		}));
	};

	return {
		notifications: state.notifications,
		show,
		showAll,
		dismiss,
		dismissAll,
	};
}
