import { PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingFullscreen from '../components/loading-fullscreen';
import { useAccount } from '../hooks/account';

interface Props extends PropsWithChildren {
	fallback?: ReactNode;
}

export function PublicGuard({ children, fallback }: Props) {
	const { account, isLoading } = useAccount();
	if (isLoading) {
		return <LoadingFullscreen />;
	}
	if (account) {
		return fallback ? <>{fallback}</> : <Navigate to="/" />;
	}
	return <>{children}</>;
}
