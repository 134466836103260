import { OIDCClient } from '../proto/oidc_client';
import { Api } from '../services/api';

export async function getClientByID(
	api: Api,
	client_id: string
): Promise<OIDCClient> {
	const { client } = await api.request<{ client: OIDCClient }>(
		'/v1/clients/get-client',
		{ client_id }
	);
	return client;
}
