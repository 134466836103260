import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { classNames } from 'pkg/util/classnames';
import {
	Fragment,
	PropsWithChildren,
	ReactNode,
	createContext,
	useMemo,
	useState,
} from 'react';

const BUTTON_HEIGHT = 32;
const BUTTON_WIDTH = 220;
const SEPARATOR_HEIGHT = 1;
const SEPARATOR_PADDING_Y = 8;
const BUTTON_LIST_PADDING_Y = 8;
const BUTTON_LIST_HEADER_HEIGHT = 30;

export interface ContextMenu {
	header?: ReactNode;
	buttons: (Button | 'separator')[];
}

export interface Button {
	hide?: boolean;
	icon?: ReactNode;
	text?: ReactNode;
	textStyle?: string;
	onClick?: () => void;
	children?: ContextMenu;
}

interface State {
	show: boolean;
	position?: { x: number; y: number };
	menu?: ContextMenu;
}

// The initial state to use for the context
const initialState: State = {
	show: false,
};

// Store the context and the setter function
const context = createContext<State>(initialState);
let setState: React.Dispatch<React.SetStateAction<State>> | undefined;

function maxDepth(menu: ContextMenu | undefined): number {
	if (!menu) return 0;
	return (
		1 +
		menu.buttons.reduce((max, button) => {
			if (typeof button === 'string' || button.hide || !button.children)
				return max;
			return Math.max(max, maxDepth(button.children));
		}, 0)
	);
}

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	// Use the overall state of the context
	const [state, _setState] = useState(initialState);
	setState = _setState;

	const hide = () =>
		_setState((state) => ({
			...state,
			show: false,
		}));

	interface PositionInfo {
		position?: { x: number; y: number };
		flowDirection: 'left' | 'right';
	}

	const { position, flowDirection } = useMemo<PositionInfo>(() => {
		if (!state.position)
			return {
				position: undefined,
				flowDirection: 'right',
			};
		const maxExpandedWidth = BUTTON_WIDTH * maxDepth(state.menu);
		const flowDirection =
			state.position.x > window.innerWidth - maxExpandedWidth
				? 'left'
				: 'right';
		const position = {
			x: Math.min(
				state.position.x,
				window.innerWidth - BUTTON_WIDTH - 10
			),
			y: state.position.y,
		};
		return {
			position,
			flowDirection,
		};
	}, [state.position, state.menu]);

	return (
		<context.Provider value={state}>
			<Transition.Root show={state.show} as={Fragment} appear>
				<div
					className="fixed w-full h-full z-50 top-0 left-0"
					onClick={hide}
				>
					<Transition.Child
						enter="transform transition ease-in-out duration-150 origin-center"
						enterFrom="opacity-0 scale-y-95"
						enterTo="opacity-100 scale-y-100"
						leave="transform transition ease-in-out duration-150 origin-center"
						leaveFrom="opacity-100 scale-y-100"
						leaveTo="opacity-0 scale-y-95"
					>
						{state.menu && position && (
							<ButtonsList
								menu={state.menu}
								position={{
									left: position.x,
									top: position.y,
								}}
								clientY={position.y}
								hideContextMenu={hide}
								root={true}
								flowDirection={flowDirection}
							/>
						)}
					</Transition.Child>
				</div>
			</Transition.Root>
			<>{children}</>
		</context.Provider>
	);
}

interface ButtonsListProps {
	menu: ContextMenu;
	position: { left: string | number; top: number };
	clientY: number;
	hideContextMenu: () => void;
	root: boolean;
	flowDirection: 'right' | 'left';
}

function ButtonsList({
	menu,
	position,
	clientY,
	hideContextMenu,
	root,
	flowDirection,
}: ButtonsListProps) {
	const anyButtonHasIcon = menu.buttons.some(
		(button) => typeof button !== 'string' && button.icon
	);
	const listHeight = useMemo(() => {
		const listPadding =
			2 * BUTTON_LIST_PADDING_Y +
			(menu.header ? BUTTON_LIST_HEADER_HEIGHT : 0);
		return menu.buttons
			.filter((button) => typeof button === 'string' || !button.hide)
			.map((button) => {
				if (typeof button === 'string')
					return SEPARATOR_PADDING_Y * 2 + SEPARATOR_HEIGHT;
				return BUTTON_HEIGHT;
			})
			.reduce((sum, height) => sum + height, listPadding);
	}, [menu]);
	const shiftY = useMemo(() => {
		const listBottomY = clientY + listHeight;
		if (listBottomY > window.innerHeight) {
			return window.innerHeight - listBottomY - 10;
		}
		if (!root) {
			return -BUTTON_LIST_PADDING_Y;
		}
		return 0;
	}, [position, listHeight]);
	return (
		<div
			className={classNames(
				'absolute shadow-lg bg-slate-600 text-white ring-1 ring-black ring-opacity-5 z-50',
				{
					'rounded-lg': root,
					'rounded-b-lg rounded-tr-lg':
						!root && flowDirection === 'right',
					'rounded-b-lg rounded-tl-lg':
						!root && flowDirection === 'left',
				}
			)}
			style={{
				left: position.left,
				top: position.top + shiftY,
				width: BUTTON_WIDTH,
			}}
			onClick={(e) => e.stopPropagation()}
		>
			{menu.header && (
				<div
					className={classNames(
						'bg-black/20 text-xs px-3 text-ellipsis whitespace-nowrap overflow-hidden',
						{
							'rounded-tl-lg': root || flowDirection == 'left',
							'rounded-tr-lg': root || flowDirection == 'right',
						}
					)}
					style={{
						height: BUTTON_LIST_HEADER_HEIGHT,
						lineHeight: `${BUTTON_LIST_HEADER_HEIGHT}px`,
					}}
				>
					{menu.header}
				</div>
			)}
			<div
				style={{
					paddingTop: BUTTON_LIST_PADDING_Y,
					paddingBottom: BUTTON_LIST_PADDING_Y,
				}}
			>
				{menu.buttons.map((button, i) =>
					button === 'separator' ? (
						<div
							key={i}
							style={{
								paddingTop: SEPARATOR_PADDING_Y,
								paddingBottom: SEPARATOR_PADDING_Y,
							}}
						>
							<div
								className="bg-black/30"
								style={{ height: SEPARATOR_HEIGHT }}
							></div>
						</div>
					) : (
						<ButtonComp
							key={i}
							button={button}
							hideContextMenu={hideContextMenu}
							iconSpace={anyButtonHasIcon}
							flowDirection={flowDirection}
							clientY={
								clientY +
								shiftY +
								BUTTON_LIST_PADDING_Y +
								BUTTON_HEIGHT * i
							}
						/>
					)
				)}
			</div>
		</div>
	);
}

interface ButtonCompProps {
	button: Button;
	hideContextMenu: () => void;
	iconSpace: boolean;
	flowDirection: 'right' | 'left';
	clientY: number;
}

function ButtonComp({
	button,
	hideContextMenu,
	iconSpace,
	flowDirection,
	clientY,
}: ButtonCompProps) {
	const [hovering, setHovering] = useState(false);
	return (
		<div
			className="relative"
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
		>
			<div
				className={classNames(
					'group w-full cursor-pointer flex px-1 text-sm hover:bg-white/10 hover:text-opacity-60'
				)}
				onClick={() => {
					button.onClick?.();
					hideContextMenu();
				}}
				style={{
					height: `${BUTTON_HEIGHT}px`,
					lineHeight: `${BUTTON_HEIGHT}px`,
				}}
			>
				{iconSpace && (
					<div className="flex-grow-0 flex-shrink-0 w-8 text-center">
						{button.icon && (
							<span
								className={button.textStyle ?? 'text-gray-400'}
							>
								{button.icon}
							</span>
						)}
					</div>
				)}
				<div className="flex-grow flex-shrink">
					<span className={button.textStyle}>{button.text}</span>
				</div>
				{button.children && (
					<div className="flex-grow-0 flex-shrink-0 w-4">
						<FontAwesomeIcon
							icon={faCaretRight}
							className="text-right text-gray-400"
						/>
					</div>
				)}
			</div>
			{hovering && button.children && (
				<ButtonsList
					menu={button.children}
					position={{
						left: flowDirection === 'right' ? '100%' : '-100%',
						top: 0,
					}}
					clientY={clientY}
					hideContextMenu={hideContextMenu}
					root={false}
					flowDirection={flowDirection}
				/>
			)}
		</div>
	);
}

export function useContextMenu() {
	const showAt = (position: { x: number; y: number }, menu: ContextMenu) => {
		const shownButtonsCount = menu.buttons.filter(
			(b) => typeof b !== 'string' && !b.hide
		).length;
		if (shownButtonsCount === 0) return;
		setState?.((state) => ({
			...state,
			show: true,
			position,
			menu,
		}));
	};

	return {
		showAt,
		showClick: (event: MouseEvent, menu: ContextMenu) =>
			showAt({ x: event.clientX + 10, y: event.clientY }, menu),
	};
}
