import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useAccount } from '@internal/auth-web/hooks/account';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import LoadingSection from '../../components/loading-section';
import AccountForm from '../../components/user/account-form';
import DashboardToolbar from '../../components/user/dashboard-toolbar';
import TextButton from '../../components/user/toolbar/text-button';
import UserDashboardLayout from '../../layouts/user-dashboard';

export default function AccountPage() {
	const { account } = useAccount();
	const [editing, setEditing] = useState(false);
	// const toolbar = (
	// 	<DashboardToolbar
	// 		right={
	// 			[
	// 				// editing ? undefined : <TextButton
	// 				// 	text="Edit"
	// 				// 	icon={PencilSquareIcon}
	// 				// 	className="text-white bg-indigo-600 hover:bg-indigo-500 transition-colors"
	// 				// 	onClick={() => setEditing(true)}
	// 				// />,
	// 			]
	// 		}
	// 	/>
	// );
	const toolbar = undefined;
	return (
		<UserDashboardLayout toolbar={toolbar}>
			<Helmet>
				<title>Account | Spire Technology</title>
			</Helmet>
			<div className="w-full max-w-3xl mx-auto py-10">
				<div>
					<h1 className="text-3xl font-semibold">Account</h1>
					<p className="mt-2 text-sm leading-6 text-gray-400">
						Manage your account details.
					</p>
				</div>
				<div className="mt-6">
					{account ? (
						<AccountForm
							account={account}
							editing={editing}
							onCancel={() => setEditing(false)}
							onSave={() => setEditing(false)}
						/>
					) : (
						<LoadingSection />
					)}
				</div>
			</div>
		</UserDashboardLayout>
	);
}
