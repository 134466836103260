import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'pkg/util/classnames';
import { errorToString } from 'pkg/util/errors';
import { ucfirst } from 'pkg/util/uppercase';
import { useRef, useState } from 'react';
import { signup, verify } from '../api/signup';
import PasswordStrengthBox from '../components/password-strength-box';
import { useApi } from '../hooks/api';
import { Api } from '../services/api';

interface Props {
	onSignup: (api: Api) => void;
}

type FormStatus =
	| { type: 'loading' }
	| { type: 'form-signup'; submitting: boolean; error: string | null }
	| { type: 'form-verify'; submitting: boolean; error: string | null }
	| { type: 'redirecting' };

interface FormState {
	name: string;
	email: string;
	password: string;
	signup_intent: string | null;
	verification_code: string;
}

export default function SignupForm({ onSignup }: Props) {
	const { api, setAuthToken } = useApi();
	const emailFieldRef = useRef<HTMLInputElement>(null);
	const passwordFieldRef = useRef<HTMLInputElement>(null);
	const [status, setStatus] = useState<FormStatus>({
		type: 'form-signup',
		submitting: false,
		error: null,
	});
	const [formState, setFormState] = useState<FormState>({
		name: '',
		email: '',
		password: '',
		signup_intent: null,
		verification_code: '',
	});

	const successWithToken = (token: string) => {
		setStatus({ type: 'redirecting' });
		const newApi = setAuthToken(token);
		onSignup(newApi);
	};

	const clickedSignup = async () => {
		setStatus({
			type: 'form-signup',
			submitting: true,
			error: null,
		});
		try {
			const { signup_intent } = await signup(api, {
				name: formState.name,
				email: formState.email,
				password: formState.password,
			});
			setFormState((state) => ({
				...state,
				signup_intent,
			}));
			setStatus({
				type: 'form-verify',
				submitting: false,
				error: null,
			});
		} catch (err) {
			setStatus({
				type: 'form-signup',
				submitting: false,
				error: ucfirst(errorToString(err)),
			});
			console.error(err);
		}
	};

	const clickedVerify = async () => {
		setStatus({
			type: 'form-verify',
			submitting: true,
			error: null,
		});
		try {
			const { correct_code, token } = await verify(api, {
				signup_request: {
					name: formState.name,
					email: formState.email,
					password: formState.password,
				},
				signup_intent: formState.signup_intent!,
				code: formState.verification_code,
			});
			if (!correct_code) {
				setStatus({
					type: 'form-verify',
					submitting: false,
					error: 'Incorrect verification code.',
				});
				return;
			}
			successWithToken(token);
		} catch (err) {
			setStatus({
				type: 'form-verify',
				submitting: false,
				error: ucfirst(errorToString(err)),
			});
			console.error(err);
		}
	};

	if (status.type === 'loading') {
		return <>Loading...</>;
	} else if (status.type === 'form-signup') {
		return (
			<div className="space-y-6">
				{status.error && (
					<div className="-mt-5 bg-red-500/30 p-4 text-sm font-semibold border border-red-500 rounded-md">
						{status.error}
					</div>
				)}
				<div>
					<label
						htmlFor="name"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Name
					</label>
					<div className="mt-2">
						<input
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							required
							value={formState.name}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									...state,
									name: value,
								}));
							}}
							className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									emailFieldRef.current?.select();
								}
							}}
						/>
					</div>
				</div>

				<div>
					<label
						htmlFor="email"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Email address
					</label>
					<div className="mt-2">
						<input
							id="email"
							name="email"
							type="email"
							autoComplete="off"
							required
							ref={emailFieldRef}
							value={formState.email}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									...state,
									email: value,
								}));
							}}
							className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									passwordFieldRef.current?.select();
								}
							}}
						/>
					</div>
				</div>

				<div>
					<label
						htmlFor="password"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Password
					</label>
					<div className="mt-2">
						<input
							id="password"
							name="password"
							type="password"
							autoComplete="off"
							required
							ref={passwordFieldRef}
							value={formState.password}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									...state,
									password: value,
								}));
							}}
							className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									clickedSignup();
								}
							}}
						/>
					</div>
					<PasswordStrengthBox password={formState.password} />
				</div>

				<div>
					<button
						type="submit"
						className="w-full rounded-md disabled:bg-blue-700/50 disabled:italic bg-blue-600 px-3 h-10 leading-10 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
						disabled={status.submitting}
						onClick={clickedSignup}
					>
						<FontAwesomeIcon
							icon={faSpinnerThird}
							spin
							className={classNames('transition-all', {
								'opacity-0 w-0': !status.submitting,
								'opacity-100 w-4 mr-1': status.submitting,
							})}
						/>
						<span>
							{status.submitting ? 'Signing up...' : 'Sign up'}
						</span>
					</button>
				</div>
			</div>
		);
	} else if (status.type === 'form-verify') {
		return (
			<div className="space-y-6">
				{status.error ? (
					<div className="-mt-5 bg-red-500/30 p-4 text-sm font-semibold border border-red-500 rounded-md">
						{status.error}
					</div>
				) : (
					<div className="-mt-5 bg-emerald-500/30 p-4 text-sm rounded-md">
						We sent an email to{' '}
						<span className="font-semibold">{formState.email}</span>
						. Please enter the code to verify your account.
					</div>
				)}
				<div>
					<label
						htmlFor="verificationcode"
						className="block text-sm font-medium leading-6 text-gray-400"
					>
						Verification Code
					</label>
					<div className="mt-2">
						<input
							id="verificationcode"
							name="verificationcode"
							type="text"
							autoComplete="off"
							required
							value={formState.verification_code}
							onInput={(e) => {
								const value = e.currentTarget.value;
								setFormState((state) => ({
									...state,
									verification_code: value,
								}));
							}}
							className="block w-full text-black text-center font-mono text-2xl rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0"
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									clickedVerify();
								}
							}}
						/>
					</div>
				</div>
				<div>
					<button
						type="submit"
						className="w-full rounded-md disabled:bg-blue-700/50 disabled:italic bg-blue-600 px-3 h-10 leading-10 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
						disabled={status.submitting}
						onClick={clickedVerify}
					>
						<FontAwesomeIcon
							icon={faSpinnerThird}
							spin
							className={classNames('transition-all', {
								'opacity-0 w-0': !status.submitting,
								'opacity-100 w-4 mr-1': status.submitting,
							})}
						/>
						<span>
							{status.submitting ? 'Submitting...' : 'Submit'}
						</span>
					</button>
				</div>
			</div>
		);
	} else if (status.type === 'redirecting') {
		return <>Logging in...</>;
	} else {
		return <></>;
	}
}
