import AppsGrid from '@internal/auth-web/components/user/apps-grid';
import { Helmet } from 'react-helmet-async';
import UserDashboardLayout from '../../layouts/user-dashboard';

export default function HomePage() {
	return (
		<UserDashboardLayout>
			<Helmet>
				<title>Apps | Spire Technology</title>
			</Helmet>
			<div className="w-full max-w-3xl mx-auto py-10">
				<div>
					<h1 className="text-3xl font-semibold">Apps</h1>
					<p className="mt-2 text-sm leading-6 text-gray-400">
						Use one account to access all your Spire apps.
					</p>
				</div>
				<div className="mt-6">
					<AppsGrid />
				</div>
			</div>
		</UserDashboardLayout>
	);
}
