import { WebAuthnClient } from '@spiretechnology/js-webauthn';
import { useEffect, useMemo, useState } from 'react';
import {
	deleteMfaCredential,
	listMfaCredentials,
	registerChallenge,
	registerVerify,
} from '../api/mfa';
import { Credential } from '../proto/mfa';
import { useAccount } from './account';
import { useApi } from './api';

export function useMfaCredentials() {
	const { api } = useApi();
	const { account } = useAccount();

	const webauthn = useMemo(() => new WebAuthnClient(), []);
	const [credentials, setCredentials] = useState<Credential[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!account) {
			setCredentials([]);
			setLoading(false);
			return;
		}
		(async () => {
			try {
				setLoading(true);
				const credentials = await listMfaCredentials(api);
				setCredentials(credentials);
			} catch (e) {
				console.error('Error loading credentials: ', e);
			} finally {
				setLoading(false);
			}
		})();
	}, [account]);

	const register = async () => {
		// Request a challenge from the server
		const challenge = await registerChallenge(api);

		// Register a device with the WebAuthn client
		const response = await webauthn.register(challenge);

		// Send the response to the server
		const credential = await registerVerify(api, response);
		setCredentials((creds) => [...creds, credential]);
	};

	const deleteCredential = async (credential: Credential) => {
		setCredentials((creds) => creds.filter((c) => c.id !== credential.id));
		await deleteMfaCredential(api, credential.id);
	};

	return {
		loading,
		credentials,
		register,
		deleteCredential,
	};
}
