import { useAccount } from './account';

export interface App {
	name: string;
	image: string;
	link: string;
}

export function useApps() {
	const { account } = useAccount();
	const apps: App[] = [
		{
			name: 'Spire Delta',
			image: '/apps/spire-delta.png',
			link: `https://spiredelta.com/auth/login?idp_init=${encodeURIComponent(
				account?.email ?? ''
			)}`,
		},
	];
	return { apps };
}
