import {
	AuthenticationChallenge,
	AuthenticationResponse,
} from '@spiretechnology/js-webauthn';
import { Api } from '../services/api';

export type BasicLoginResponse =
	| { success: LoginSuccessResponse; mfa_needed: null }
	| { success: null; mfa_needed: MfaNeededResponse };

export interface LoginSuccessResponse {
	token: string;
}

export interface MfaNeededResponse {
	login_intent: string;
	challenge: AuthenticationChallenge;
}

export async function basic_login(
	api: Api,
	req: { email: string; password: string }
): Promise<BasicLoginResponse> {
	return api.request<BasicLoginResponse>('/v1/auth/basic-login', req);
}

export interface MfaLoginRequest {
	login_intent: string;
	response: AuthenticationResponse;
}

export async function mfa_login(
	api: Api,
	req: MfaLoginRequest
): Promise<LoginSuccessResponse> {
	return api.request<LoginSuccessResponse>('/v1/auth/mfa-login', req);
}

export interface GetOIDCCodeRequest {
	code_challenge: string;
	code_challenge_method: string;
	client_id: string;
	redirect_uri: string;
	scope: string[];
}

export async function get_oidc_code(
	api: Api,
	req: GetOIDCCodeRequest
): Promise<string> {
	const { code } = await api.request<{ code: string }>(
		'/v1/auth/oidc-get-code',
		req
	);
	return code;
}
