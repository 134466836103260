export function classNames(
	...args: (string | { [key: string]: boolean })[]
): string {
	return args
		.reduce((sum: string[], arg) => {
			if (typeof arg === 'string') {
				sum.push(arg);
			} else {
				sum.push(...Object.keys(arg).filter((key) => arg[key]));
			}
			return sum;
		}, [] as string[])
		.join(' ');
}
