interface PasswordCheck {
	name: string;
	check: (password: string) => boolean;
}

export const PASSWORD_CHECKS: PasswordCheck[] = [
	{
		name: '8-64 characters long',
		check: (password: string) =>
			password.length >= 8 && password.length <= 64,
	},
	{
		name: 'one uppercase letter',
		check: (password: string) =>
			containsAny(password, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'),
	},
	// {
	//     name: 'cannot start or end with a space',
	//     check: (password: string) => password.trim() === password,
	// },
	{
		name: 'one lowercase letter',
		check: (password: string) =>
			containsAny(password, 'abcdefghijklmnopqrstuvwxyz'),
	},
	{
		name: 'one number',
		check: (password: string) => containsAny(password, '0123456789'),
	},
	{
		name: 'one special character',
		check: (password: string) =>
			containsAny(password, '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'),
	},
];

function containsAny(password: string, charset: string): boolean {
	for (let i = 0; i < password.length; i++) {
		if (charset.includes(password[i])) {
			return true;
		}
	}
	return false;
}
