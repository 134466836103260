import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useContext,
	useState,
} from 'react';
import Prompt, { Button, Props as PromptProps } from '../components/prompt';

interface PromptWrapperProps {
	id: number;
	props: PromptProps;
	current: boolean;
	resolve: (button: Button | null) => void;
}

interface State {
	queue: Omit<PromptWrapperProps, 'current'>[];
}

// The initial state to use for the context
const initialState: State = {
	queue: [],
};

let nextPromptID = 0;

// Store the context and the setter function
const context = createContext<State>(initialState);

let setState: Dispatch<SetStateAction<State>> | undefined;

// Provider to give children access to the context
export function Provider({ children }: PropsWithChildren) {
	const [state, _setState] = useState(initialState);
	setState = _setState;
	return (
		<context.Provider value={state}>
			{state.queue.map((prompt, index) => (
				<PromptWrapper
					key={prompt.id}
					{...prompt}
					current={index === 0}
				/>
			))}
			{children}
		</context.Provider>
	);
}

function PromptWrapper({ props, current, resolve }: PromptWrapperProps) {
	const [open, setOpen] = useState(true);
	return (
		<Prompt
			{...props}
			open={current && open}
			onClose={(button) => {
				resolve(button);
				// Hide the prompt (begin the animation)
				setOpen(false);
				setTimeout(() => {
					// Update the queue by removing the leftmost prompt
					setState?.((state) => {
						const newQueue = [...state.queue];
						newQueue.shift();
						return {
							...state,
							queue: newQueue,
						};
					});
				}, 300);
			}}
		/>
	);
}

export function usePrompts() {
	const state = useContext<State>(context);

	// Get the state from the context
	const prompt = (props: PromptProps /* ... */): Promise<Button | null> => {
		return new Promise((resolve) => {
			// Add the prompt to the queue
			setState?.((state) => {
				const id = nextPromptID;
				nextPromptID++;
				return {
					...state,
					queue: [
						...state.queue,
						{
							id,
							props,
							resolve,
						},
					],
				};
			});
		});
	};

	return {
		...state,
		prompt,
	};
}
