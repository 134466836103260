import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider as ContextMenuProvider } from 'pkg/hooks/contextmenu';
import { Provider as DialogsProvider } from 'pkg/hooks/dialogs';
import { Provider as NotificationsProvider } from 'pkg/hooks/notifications';
import { Provider as PromptsProvider } from 'pkg/hooks/prompts';
import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
	Navigate,
	Outlet,
	RouterProvider,
	createBrowserRouter,
} from 'react-router-dom';
import { PrivateGuard } from './guards/guard-private';
import { PublicGuard } from './guards/guard-public';
import { Provider as AccountProvider } from './hooks/account';
import { Provider as ApiProvider } from './hooks/api';
import Error404Page from './pages/404';
import LoginPage from './pages/login';
import SignupPage from './pages/signup';
import AccountPage from './pages/user/account';
import HomePage from './pages/user/home';
import MfaPage from './pages/user/mfa';
import './styles/scrollbar.css';

const router = createBrowserRouter([
	{
		path: '',
		element: <Navigate to="/dashboard" />,
	},
	{
		path: '/login',
		element: (
			<PublicGuard>
				<LoginPage />
			</PublicGuard>
		),
	},
	{
		path: '/signup',
		element: (
			<PublicGuard>
				<SignupPage />
			</PublicGuard>
		),
	},
	{
		path: '/sso/login',
		element: <LoginPage />,
	},
	{
		path: '/sso/signup',
		element: <SignupPage />,
	},
	{
		path: '/forgot-password',
		element: <>Forgot password</>,
	},
	{
		path: '/password-reset/:token',
		element: <>PASSWORD RESET</>,
	},
	{
		path: '/dashboard',
		element: (
			<PrivateGuard>
				<Outlet />
			</PrivateGuard>
		),
		children: [
			{
				path: '',
				element: <Navigate to="/dashboard/home" />,
			},
			{
				path: 'home',
				element: <HomePage />,
			},
			{
				path: 'account',
				element: <AccountPage />,
			},
			{
				path: 'mfa',
				element: <MfaPage />,
			},
		],
	},
	{
		path: '*',
		element: <Error404Page />,
	},
]);

// InnerProviders are providers that are general across the app, but need to be layered
// inside the TeamProviders, or other, so that passed dialog/prompt/notification renderers
// can access the data from those providers.
function InnerProviders({ children }: PropsWithChildren) {
	const layers = [
		ApiProvider,
		AccountProvider,
		NotificationsProvider,
		PromptsProvider,
		DialogsProvider,
	];
	let node = <>{children}</>;
	for (let i = layers.length - 1; i >= 0; i--) {
		const ProviderLayer = layers[i];
		node = <ProviderLayer>{node}</ProviderLayer>;
	}
	return node;
}

export default function AuthWebApp() {
	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
	});
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<ContextMenuProvider>
						<InnerProviders>
							<RouterProvider router={router} />
						</InnerProviders>
					</ContextMenuProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</HelmetProvider>
	);
}
